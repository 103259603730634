import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ApplicationConfig } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from "@angular/material/form-field";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule, provideAnimations } from "@angular/platform-browser/animations";
import { JwtModule } from "@auth0/angular-jwt";
import { StoreModule } from "@ngrx/store";
import { NgCircleProgressModule } from "ng-circle-progress";
import { NgxPermissionsModule } from "ngx-permissions";
import { ToastrModule } from "ngx-toastr";
import { AppComponent } from "./app.component";
import { routing } from "./app.routing.module";
import { reducers } from "./reducers";
import { AuthGuard } from "./shared/services/auth-guard.service";
import { ExternalService } from "./shared/services/external.service";
import { SharedModule } from "./shared/shared.module";
import { UtilsModule } from "./shared/utils/utils.module";
import { RouterModule } from "@angular/router";
import { JwtInterceptor } from "./shared/services/jwt.interceptor";

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi())
  ]
}

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    SharedModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      progressBar: true,
      maxOpened: 5,
      autoDismiss: true,
      progressAnimation: 'increasing',
    }),
    HttpClientModule,
    JwtModule,
    StoreModule.forRoot(reducers),
    UtilsModule,
    NgxPermissionsModule.forRoot(),
    RouterModule,
    routing,

  ],
  providers: [

    ExternalService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideHttpClient(),
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'auto' } }
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }


