// export const environment = {
//   production: true,
//   apiEndPoint: './wims-property-truth/public/api',
//   imageEndPoint: './wims-property-truth/public/',
//   googleMapsApiKey: 'AIzaSyBrlB81cnPdDjX25xmUdnDpwVdQ_ASC-fo'
// };

export const environment = {
  production: true,
  apiEndPoint: '/api',
  imageEndPoint: '/api/images',
  googleMapsApiKey: 'AIzaSyBrlB81cnPdDjX25xmUdnDpwVdQ_ASC-fo'
};


